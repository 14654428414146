import React, { createContext, useContext, useState } from 'react';

const BackgroundColorContext = createContext();

export const BackgroundColorProvider = ({ children }) => {
  const [isBackgroundBlack, setIsBackgroundBlack] = useState(true);
  return (
    <BackgroundColorContext.Provider value={{ isBackgroundBlack, setIsBackgroundBlack }}>
      {children}
    </BackgroundColorContext.Provider>
  );
};

export const useBackgroundColor = () => {
  return useContext(BackgroundColorContext);
};
