import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function AC() {
    return (
        <div className='font-mono min-h-screen h-auto text-white'>
            <Helmet>
                <title>AC Hydro-Quebec</title>
            </Helmet>
            <Link to='/' className='relative inset-4 text-2xl hover:text-gray-600'>Return to Alexandre Resume</Link>
            <div className='max-w-max m-auto'>
                <h1 className='py-10 text-5xl bg-gradient-to-r from-white to-blue-600 inline-block text-transparent bg-clip-text'>Assetto Corsa Hydro-Quebec Download</h1>
                <a href='https://discord.gg/AMEKQZgEVf' className='absolute top-4 right-4 hover:text-blue-600 text-3xl'>Join The Discord</a>
            </div>
            <div className='flex flex-col justify-around m-auto w-1/2 h-96 text-center text-4xl'>
                <Link to="/AC/tracks" className='hover:text-blue-600'>Tracks list</Link>
                <Link to="/AC/cars" className='hover:text-blue-600'>Cars List</Link>
                <Link to="/AC/fileUpload" className='hover:text-blue-600'>Upload</Link>
            </div>
        </div>
    );
}

export default AC;