import axios from 'axios';
import Cookies from 'js-cookie';
import { getTokenFromCookie } from './cookie';



export const getUser = async (choice) => {
  const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/auth/me`;
  const token = getTokenFromCookie();

  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.get(apiEndpoint, { headers });
    if(choice === "role"){
      return(response.data.data.role);
    }
    else{
      return(response.data.data.name)
    }
  } catch (error) {
    console.log(error);
  }
};

export const logout = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_BACKEND_LINK+'/auth/logout');
    if (response.data.success) {
      Cookies.remove('tokenAlex');

      window.location.reload();
    } else {
      console.error('Logout failed');
    }
  } catch (error) {
    console.error('Error during logout:', error);
  }  
};

export const sendResetPassword = async (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const data = Object.fromEntries(formData);

  const apiEndpoint = process.env.REACT_APP_BACKEND_LINK+'/auth/updatepassword';
  const token = getTokenFromCookie();

  const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
  };

  const formattedData = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
  };

  try {
      const response = await axios.put(apiEndpoint, formattedData, { headers });
      return(response.data.success);
  } catch (error) {
      console.log(error);
  }
};

export const handleSubmit = async (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const data = Object.fromEntries(formData);

  const apiEndpoint = data.hasOwnProperty('userUserName')
      ? process.env.REACT_APP_BACKEND_LINK+'/auth/login'
      : process.env.REACT_APP_BACKEND_LINK+'/auth/register';

  const formattedData = data.hasOwnProperty('userUserName')
      ? {
          email: data.userUserName,
          password: data.userPassword
      }
      : {
          name: data['name'],
          email: data['e-mail'],
          password: data.password
      };

  try {
      const response = await axios.post(apiEndpoint, formattedData);

      const token = response.data.token;
      console.log("response token data: " + token);
      Cookies.remove("tokenAlex");
      Cookies.set("tokenAlex", token, { expires: 7 });
      return(false)
      

  } catch (error) {
      return(error);
  }
};

export const requestRole = async (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const data = Object.fromEntries(formData);

  const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/auth/becomecustomer`;
  const token = getTokenFromCookie();

  const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
  };

  const formattedData = {
      customerToken: data.token
  };

  try {
      const response = await axios.post(apiEndpoint, formattedData, { headers });

      return `Vous avez obtenue le rôle: ${response.data.data.role}`;

  } catch (error) {
      console.error(error);
      return "Erreur avec le token";
  }
};