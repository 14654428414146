import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useBackgroundColor } from '../BackgroundColorContext';
import { getUser, logout } from '../../api/auth';

function UserAccount(){
    const [username, setUsername] = useState(null);
    const [role, setRole] = useState(null);

    const { isBackgroundBlack } = useBackgroundColor();

    useEffect(() => {
      const fetchUsername = async () => {
        const user = await getUser(); // Ensure getUser is awaited
        setUsername(user);
      };
  
      fetchUsername();
   }, []); 

   useEffect(() => {
    const fetchRole = async () => {
      const user = await getUser("role"); // Ensure getUser is awaited
      setRole(user);
    };

    fetchRole();
 }, []); 

    return(
        <div className={`${isBackgroundBlack ? "bg-zinc-900 text-gray-100" : "bg-white text-black"} font-mono min-h-screen`}>
            <h1 className='text-center text-6xl my-10'>Bienvenue<br />{username}</h1>
            <div className='text-4xl m-auto text-center flex flex-col gap-y-12'>
              <Link className="hover:text-gray-600" to="/role">Changer votre rôle</Link>
              <Link className='hover:text-gray-600' to="/password">Changer votre mots de passe</Link>
              <button className='hover:text-gray-600' onClick={logout}>Se déconnecter</button>
              <p>{role ? `Rôle: ${role}` : "Aucun rôle"}</p>
            </div>
        </div>
    )
}



export default UserAccount;