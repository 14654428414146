import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import './MoneyCounter.css'


function MoneyCounter() {
    

    const [amounts, setAmounts] = useState({
        fiveCent: "",
        tenCent: "",
        twentyFiveCent: "",
        oneDollar: "",
        twoDollar: "",
        fiveDollar: "",
        tenDollar: "",
        twentyDollar: "",
        fiftyDollar: "",
        hundredDollar: "",
        });

    const [individualTotals, setIndividualTotals] = useState({
        fiveCentTotal: 0,
        tenCentTotal: 0,
        twentyFiveCentTotal: 0,
        oneDollarTotal: 0,
        twoDollarTotal: 0,
        fiveDollarTotal: 0,
        tenDollarTotal: 0,
        twentyDollarTotal: 0,
        fiftyDollarTotal: 0,
        hundredDollarTotal: 0,
    });

    const [total, setTotal] = useState(0);
    const prevTotalRef = useRef(0);

    useEffect(() => {

        setIndividualTotals({
            fiveCentTotal: (amounts.fiveCent * 0.05 || 0),
            tenCentTotal: (amounts.tenCent * 0.1 || 0),
            twentyFiveCentTotal: (amounts.twentyFiveCent * 0.25 || 0),
            oneDollarTotal: (amounts.oneDollar || 0),
            twoDollarTotal: (amounts.twoDollar * 2 || 0),
            fiveDollarTotal: (amounts.fiveDollar * 5 || 0),
            tenDollarTotal: (amounts.tenDollar * 10 || 0),
            twentyDollarTotal: (amounts.twentyDollar * 20 || 0),
            fiftyDollarTotal: (amounts.fiftyDollar * 50 || 0),
            hundredDollarTotal: (amounts.hundredDollar * 100 || 0),
        });
    }, [amounts]);

    useEffect(() => {
        const newTotal =
          individualTotals.fiveCentTotal +
          individualTotals.tenCentTotal +
          individualTotals.twentyFiveCentTotal +
          individualTotals.oneDollarTotal +
          individualTotals.twoDollarTotal +
          individualTotals.fiveDollarTotal +
          individualTotals.tenDollarTotal +
          individualTotals.twentyDollarTotal +
          individualTotals.fiftyDollarTotal +
          individualTotals.hundredDollarTotal;
    
        if (newTotal !== prevTotalRef.current) {
          setTotal(newTotal);
          prevTotalRef.current = newTotal;
        }
      }, [individualTotals]);

    const reset = () => {
        setAmounts({
            fiveCent: "",
            tenCent: "",
            twentyFiveCent: "",
            oneDollar: "",
            twoDollar: "",
            fiveDollar: "",
            tenDollar: "",
            twentyDollar: "",
            fiftyDollar: "",
            hundredDollar: "",
        });

        setIndividualTotals({
            fiveCentTotal: 0,
            tenCentTotal: 0,
            twentyFiveCentTotal: 0,
            oneDollarTotal: 0,
            twoDollarTotal: 0,
            fiveDollarTotal: 0,
            tenDollarTotal: 0,
            twentyDollarTotal: 0,
            fiftyDollarTotal: 0,
            hundredDollarTotal: 0,
        });

        document.getElementById("quantityEntry").reset();
    };

    const handleAmountChange = (e, coinType) => {
        const value = parseFloat(e.target.value);
        setAmounts({ ...amounts, [coinType]: value });
    };

    return (
        <div className="bg-blue-200 min-h-screen">
            <Helmet>
                <title>Money Counter</title>
                <meta name="description" content="Une petite application web pour compter votre caisse efficacement et rapidement" />
            </Helmet>
            <h1 className="text-center font-bold text-3xl sm:text-5xl py-4">Money Counter</h1>
            <div className="w-2/3 sm:w-1/3 xl:w-2/5 m-auto pt-6 pb-10 text-2xl sm:text-3xl font-bold flex flex-row flex-nowrap justify-between">
                <h2 className="">Coins</h2>
                <button onClick={reset} className="text-blue-400 hover:text-yellow-300">Reset</button>
                <h2 className="">Bills</h2>
            </div>
            <form id="quantityEntry" className="w-4/5 sm:w-3/4 2xl:w-1/2 m-auto flex flex-row flex-nowrap justify-between text-center">
                <div className="space-y-0 sm:space-y-2 w-1/3 sm:w-1/2">
                    <div>
                        <label for="fiveCentInput" className="inputLabel">5¢X</label>
                        <input 
                            type="number" 
                            id="fiveCentInput" 
                            name="fiveCentInput" 
                            className="bg-white inputMoneyCounter"
                            value={amounts.fiveCent}
                            onChange={(e) => handleAmountChange(e, 'fiveCent')}
                        />
                        <p className="singleResult">{!isNaN(individualTotals.fiveCentTotal) && individualTotals.fiveCentTotal != 0 ? "= " + individualTotals.fiveCentTotal.toFixed(2) + '$' : <br />}</p>
                    </div>
                    <div id="tenCent" className="inputRow">
                        <label for="Input" className="inputLabel">10¢X</label>
                        <input 
                            type="number" 
                            id="tenCentInput" 
                            name="tenCentInput" 
                            className="bg-white inputMoneyCounter"
                            value={amounts.tenCent}
                            onChange={(e) => handleAmountChange(e, 'tenCent')}
                        />
                        <p className="singleResult">{!isNaN(individualTotals.tenCentTotal) && individualTotals.tenCentTotal != 0 ? "= " + individualTotals.tenCentTotal.toFixed(2) + '$' : <br />}</p>
                    </div>
                    <div id="twentyFiveCent" className="inputRow">
                        <label for="twentyFiveCentInput" className="inputLabel">25¢X</label>
                        <input 
                            type="number" 
                            id="twentyFiveCentInput" 
                            name="twentyFiveCentInput" 
                            className="bg-white inputMoneyCounter"
                            value={amounts.twentyFiveCent}
                            onChange={(e) => handleAmountChange(e, 'twentyFiveCent')}
                        />
                        <p className="singleResult">{!isNaN(individualTotals.twentyFiveCentTotal) && individualTotals.twentyFiveCentTotal != 0 ? "= " + individualTotals.twentyFiveCentTotal.toFixed(2) + "$" : <br />}</p>
                    </div>
                    <div id="oneDollar" className="inputRow">
                        <label for="oneDollarInput" className="inputLabel">1$X</label>
                        <input 
                            type="number" 
                            id="oneDollarInput" 
                            name="oneDollarInput" 
                            className="bg-white inputMoneyCounter"
                            value={amounts.oneDollar}
                            onChange={(e) => handleAmountChange(e, 'oneDollar')}
                        />
                        <p className="singleResult">{!isNaN(individualTotals.oneDollarTotal) && individualTotals.oneDollarTotal != 0 ? "= " + individualTotals.oneDollarTotal.toFixed(2) + "$" : <br />}</p>
                    </div>
                    <div id="twoDollar" className="inputRow">
                        <label for="twoDollarInput" className="inputLabel">2$X</label>
                        <input 
                            type="number" 
                            id="twoDollarInput" 
                            name="twoDollarInput" 
                            className="bg-white inputMoneyCounter"
                            value={amounts.twoDollar}
                            onChange={(e) => handleAmountChange(e, 'twoDollar')}
                        />
                        <p className="singleResult">{!isNaN(individualTotals.twoDollarTotal) && individualTotals.twoDollarTotal != 0 ? "= " + individualTotals.twoDollarTotal.toFixed(2) + "$" : <br />}</p>
                    </div>
                </div>
                
                <div id="sectionBills" className="space-y-0 sm:space-y-2 w-1/3 sm:w-1/2">
                    <div id="fiveDollar" className="inputRow">
                        <label for="fiveDollarInput" className="inputLabel">5$X</label>
                        <input 
                            type="number" 
                            id="fiveDollarInput" 
                            name="fiveDollarInput" 
                            className="bg-white inputMoneyCounter"
                            value={amounts.fiveDollar}
                            onChange={(e) => handleAmountChange(e, 'fiveDollar')}
                        />
                        <p className="singleResult">{!isNaN(individualTotals.fiveDollarTotal) && individualTotals.fiveDollarTotal != 0 ? "= " + individualTotals.fiveDollarTotal.toFixed(2) + "$" : <br />}</p>
                    </div>
                    <div id="tenDollar" className="inputRow">
                        <label for="tenDollarInput" className="inputLabel">10$X</label>
                        <input 
                            type="number" 
                            id="tenDollarInput" 
                            name="tenDollarInput" 
                            className="bg-white inputMoneyCounter"
                            value={amounts.tenDollar}
                            onChange={(e) => handleAmountChange(e, 'tenDollar')}
                        />
                        <p className="singleResult">{!isNaN(individualTotals.tenDollarTotal) && individualTotals.tenDollarTotal != 0 ? "= " + individualTotals.tenDollarTotal.toFixed(2) + "$" : <br />}</p>
                    </div>
                    <div id="twentyDollar" className="inputRow">
                        <label for="twentyDollarInput" className="inputLabel">20$X</label>
                        <input 
                            type="number" 
                            id="twentyDollarInput" 
                            name="twentyDollarInput" 
                            className="bg-white inputMoneyCounter"
                            value={amounts.twentyDollar}
                            onChange={(e) => handleAmountChange(e, 'twentyDollar')}
                        />
                        <p className="singleResult">{!isNaN(individualTotals.twentyDollarTotal) && individualTotals.twentyDollarTotal != 0 ? "= " + individualTotals.twentyDollarTotal.toFixed(2) + "$" : <br />}</p>
                    </div>
                    <div id="fiftyDollar" className="inputRow">
                        <label for="fiftyDollarInput" className="inputLabel">50$X</label>
                        <input 
                            type="number" 
                            id="fiftyDollarInput" 
                            name="fiftyDollarInput" 
                            className="bg-white inputMoneyCounter"
                            value={amounts.fiftyDollar}
                            onChange={(e) => handleAmountChange(e, 'fiftyDollar')}
                        />
                        <p className="singleResult">{!isNaN(individualTotals.fiftyDollarTotal) && individualTotals.fiftyDollarTotal != 0 ? "= " + individualTotals.fiftyDollarTotal.toFixed(2) + "$" : <br />}</p>
                    </div>
                    <div id="hundredDollar" className="inputRow">
                        <label for="hundredDollarInput" className="inputLabel">100$X</label>
                        <input 
                            type="number" 
                            id="hundredDollarInput" 
                            name="hundredDollarInput" 
                            className="bg-white inputMoneyCounter"
                            value={amounts.hundredDollar}
                            onChange={(e) => handleAmountChange(e, 'hundredDollar')}
                        />
                        <p className="singleResult">{!isNaN(individualTotals.hundredDollarTotal) && individualTotals.hundredDollarTotal != 0 ? "= " + individualTotals.hundredDollarTotal.toFixed(2) + "$" : <br />}</p>
                    </div>
                </div>
            </form>
            <p id="total" className="text-center text-4xl mt-5 pb-5">{!isNaN(total) && total != 0 ? total.toFixed(2) + "$" : <br />}</p>
        </div>
    )
}

export default MoneyCounter;