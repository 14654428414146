import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import Cookies from 'js-cookie';

function AdminList() {
    const [zipList, setZipList] = useState('');
    const [role, setRole] = useState('');

    const getTokenFromCookie = () => {
        const cookies = Cookies.get("tokenAlex");
        return cookies ? cookies : null;
    };

    const requestZipList = useCallback(async () => {
        const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/admin/listziphoster`;
        const token = getTokenFromCookie();
        console.log(token)

        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.get(apiEndpoint, { headers });
            setZipList(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getUser = useCallback(async () => {
        console.log("getUser started")
        const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/auth/me`;
        const token = getTokenFromCookie();
        console.log(token)

        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.get(apiEndpoint, { headers });
            setRole(response.data.data.role)
        } catch (error) {
            console.log(error);
        }
    }, []);

    const downloadZip = async (fileId, fileName) => {
        const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/admin/downloadziphoster/${fileId}`;
        console.log("file id used is: " + fileId);
        console.log("originalName: " + fileName);
        const token = getTokenFromCookie();

        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.get(apiEndpoint, {
                responseType: 'blob',
                headers
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const requestToken = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/admin/customertoken/${data.role}`;
        const token = Cookies.get("tokenAlex")

        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.get(apiEndpoint, { headers });
            console.log(response)
            try {
                await navigator.clipboard.writeText(response.data.data);
                alert(`Le token du role ${response.data.role} à été copier`);
            }    catch (err) {
                console.error('Failed to copy text: ', err);
            }
        } catch (error) {
            console.log("error with copy")
        }
    };

    console.log(zipList);

    useEffect(() => {
        getUser();
    }, [getUser]);

    useEffect(() => {
        requestZipList();
    }, [requestZipList]);
    return (
        role === 'admin' ? (
            <div className='font-mono text-white'>
                <Helmet>
                    <title>Alexandre Sammut - Admin</title>
                </Helmet>
                <div className='m-auto mt-10 w-2/3 min-h-screen h-auto text-center text-2xl'>
                    <h1 className='pt-10 pb-4 text-5xl bg-gradient-to-r from-white to-blue-600 inline-block text-transparent bg-clip-text'>Admin Menu</h1>
                    <div>
                        <form className="text-4xl text-left flex flex-col w-3/4 m-auto" onSubmit={requestToken}>
                            <div className='relative -top-4 m-auto flex justify-around w-1/2 mb-6'>
                                <div className="text-3xl hover:text-blue-600">
                                    <input type="radio" id="option1" name="role" value="customer" />
                                    <label for="option1">Customer</label>
                                </div>
                                <div className="text-3xl hover:text-blue-600">
                                    <input type="radio" id="option2" name="role" value="HQadmin" />
                                    <label for="option2">HQadmin</label>
                                </div>
                            </div>
                            <input className="mb-5 hover:text-gray-600" type="submit" value="Request Token" />
                        </form>
                    </div>
                    <div className='my-10 flex flex-col justify-around gap-y-10'>
                        {zipList && zipList.map((zipItem, index) => (
                            <div key={index} className='bg-gray-900 border-2 rounded-xl m-auto w-1/2 text-center flex flex-col'>
                                <h2 className='text-4xl bg-gradient-to-r from-blue-600 to-white text-transparent bg-clip-text'>{zipItem.originalName}</h2>
                                <p>{`Uploaded by ${zipItem.user.name}`}</p>
                                <button className="hover:text-blue-600" onClick={() => downloadZip(zipItem._id, zipItem.originalName)}>Download</button>
                            </div>
                        ))}
                    </div>
                    <div className='h-10'/>
                </div>
            </div>
        ) : (
            <div className='bg-black text-white text-center min-h-screen'>
                <h1 className='text-7xl'>Access Denied</h1>
                <p className='text-6xl'>You do not have permission to view this page.</p>
            </div>
        )
    );
}

export default AdminList;