import axios from 'axios';
import { getTokenFromCookie } from './cookie';

export const sendRating = async (event, ratingValue) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);

    const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/reviews`;
    const token = getTokenFromCookie();

    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    const formattedData = {
        title: data.title,
        comment: data.comment,
        company: data.company,
        rating: ratingValue
    };

    try {
        await axios.post(apiEndpoint, formattedData, { headers });
        return("Commentaire ajouté");
    } catch (error) {
        return(`Une erreur s'est produite, assurez-vous d'avoir le rôle "customer", contactez-moi si le problème persiste`)
    }
};

export const getRating = async () => {
    const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/reviews`;
    const headers = {
        'Content-Type': 'application/json'
    };

    try {
        const response = await axios.get(apiEndpoint, { headers });
        return(response.data.data);
    } catch (error) {
        console.error(error);
    }
};
