import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { handleSubmit } from '../api/ACHQ';
import ProgressBar from '../componants/progressBar'; // Import the ProgressBar component

function ACUpload() {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0); // New state for tracking upload progress

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUploadProgress = (event) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setUploadProgress(percentCompleted);
    };

    return (
        <div id="upload" className='min-h-screen text-white font-mono'>
            <Helmet>
                <title>AC Upload</title>
            </Helmet>
            <Link to='/AC' className='relative inset-4 text-2xl hover:text-blue-600'>Return to menu</Link>
            <div className='min-h-full h-auto m-auto w-2/3 text-center text-2xl'>
                <h1 className='mt-10 pb-4 text-6xl bg-gradient-to-r from-white to-blue-600 inline-block text-transparent bg-clip-text'>Upload a mod</h1>
                <form enctype="multipart/form-data" onSubmit={ async (e) => {
                    e.preventDefault();
                    const result = await handleSubmit(file, handleUploadProgress); // Pass the progress handler to handleSubmit
                    setMessage(result);
                }}>
                    <div className='my-10 max-w-min max-h-min m-auto text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'>
                        <label className="absolute text-3xl inset-x-2/4 w-1/2 translate-x-[-50%]" for="fileInput">Drop your zip file here</label>
                        <input className='opacity-0 block w-96 h-96' id="fileInput" type="file" accept=".zip" onChange={handleFileChange} />
                    </div>
                    <div className='relative -top-4 m-auto flex justify-around w-1/2 mb-6'>
                        <div className="text-3xl hover:text-blue-600">
                            <input type="radio" id="option1" name="modSelect" value="track" />
                            <label for="option1">Tracks</label>
                        </div>
                        <div className="text-3xl hover:text-blue-600">
                            <input type="radio" id="option2" name="modSelect" value="car" />
                            <label for="option2">Cars</label>
                        </div>
                    </div>
                    {message && <p className='relative -top-4'>{message}</p>}
                    <ProgressBar progress={uploadProgress} /> {/* Display the progress bar */}
                    <button className='text-4xl mb-10 hover:text-blue-600' type="submit">Upload</button>
                </form>
                
            </div>
        </div>
    );
}

export default ACUpload;