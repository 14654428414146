import axios from 'axios';
import { getTokenFromCookie } from './cookie';

export const requestCarList = async (event) => {
    const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/hq/list/car`;

    try {
        const response = await axios.get(apiEndpoint);
        return(response.data.data);
    } catch (error) {
        console.log(error);
    }
};

export const downloadMod = async (fileId, fileName) => {
    const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/hq/download/${fileId}`;

    try {
        const response = await axios.get(apiEndpoint, {
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};

export const handleCopy = async (fileId, fileName) => {
    const link = `alexandresammut.com/AC/download/${fileId}/${fileName}` 
    try {
        await navigator.clipboard.writeText(link);
        alert('Car download link copied to clipboard!');
    }    catch (err) {
        console.error('Failed to copy text: ', err);
    }
};

export const requestTrackList = async () => {
    const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/hq/list/track`;

    try {
        const response = await axios.get(apiEndpoint);
        return(response.data.data);
    } catch (error) {
        console.log(error);
    }
};


export const handleSubmit = async (e, file, onUploadProgress) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('modType', e.target.modSelect.value);
    try {
        const token = getTokenFromCookie;
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        };

        const response = await axios.post(process.env.REACT_APP_BACKEND_LINK+'/hq/upload', formData, config, {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onUploadProgress(percentCompleted); // Call the progress handler with the current progress
            }
        });
        return(`Le fichier ${response.data.data.name} a été envoyez avec succès`);
    } catch (error) {
        return("Une erreur est survenue, vérifiez votre rôle ou contactez-moi");
    }
};