import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Rating from 'react-rating';
import { sendRating } from '../api/rating';
import { useBackgroundColor } from './BackgroundColorContext';

function CustomerRating() {
    const [ratingValue, setRatingValue] = useState(NaN);
    const [message, setMessage] = useState('');

    const { isBackgroundBlack } = useBackgroundColor();

    function getRating(userRating) {
        setRatingValue(userRating);
    };

    const sendRatingStart = () => {
        setMessage(sendRating(ratingValue));
    }

    return (
        <div className={`${isBackgroundBlack ? "bg-zinc-900 text-gray-100" : "bg-white text-black"} min-h-full  text-center`}>
            <Helmet>
                <title>Changer le rôle</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <div className={`${isBackgroundBlack ? "bg-zinc-900 text-gray-100" : "bg-white text-black"} font-mono  min-h-screen text-center w-3/4 m-auto`}>
                <h1 className='text-6xl py-10'>Laissez un commentaire</h1>
                <form className="text-4xl text-left flex flex-col w-3/4 m-auto" onSubmit={sendRatingStart}>
                    <label>Titre</label>
                    <input className={`${isBackgroundBlack ? "" : "border-4 border-black"} rounded-xl`} type="text" name="title" />
                    <br /><label>Commentaire</label>
                    <textarea className={`${isBackgroundBlack ? "" : "border-4 border-black"} border-black rounded-xl`} rows="6" name="comment"></textarea>
                    <br />
                    <label>Compagnie*</label>
                    <input className={`${isBackgroundBlack ? "" : "border-4 border-black"} rounded-xl`} type="text" name="company" />
                    <p className='text-base'>*Pas obligatoire</p>
                    <Rating className="text-center my-6" fractions="2" emptySymbol="fa fa-star" fullSymbol="fa fa-star text-yellow-400" onChange={getRating} />
                    <p className='text-xl text-center'>{message ? message : null}</p>
                    <input className="mb-5 hover:text-gray-600" type="submit" value="Envoyé" />
                </form>
            </div>
        </div>
    );
};

export default CustomerRating;