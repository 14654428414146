import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { requestCarList, downloadMod, handleCopy } from '../api/ACHQ';

function CarList() {
    const [carList, setCarList] = useState(NaN);

    useEffect(() => {
        const fetchCarList = async () => {
            const carList = await requestCarList(); // Assuming getRating returns a Promise
            setCarList(carList);
        };
    
        fetchCarList();
    }, []);

    return (
        <div className='font-mono text-white'>
            <Helmet>
                <title>AC Cars List</title>
            </Helmet>
            <Link to='/AC' className='relative inset-4 text-2xl hover:text-blue-600'>Return to menu</Link>
            <div className='m-auto mt-10 w-2/3 min-h-screen h-auto text-center text-2xl'>
                <h1 className='pt-10 pb-4 text-5xl bg-gradient-to-r from-white to-blue-600 inline-block text-transparent bg-clip-text'>Vehicule mods list</h1>
                <p>Please do not use the "Copy the download link", it is for bot and server only, if you want to download the mod just click on the "Download" button.</p>
                <div className='my-10 flex flex-col justify-around gap-y-10'>
                    {carList && carList.map((carItem, index) => (
                        <div key={index} className='bg-gray-900 border-2 rounded-xl m-auto w-1/2 text-center flex flex-col'>
                            <h2 className='text-4xl bg-gradient-to-r from-blue-600 to-white text-transparent bg-clip-text'>{carItem.originalName}</h2>
                            <p>{`Uploaded by ${carItem.user.name}`}</p>
                            <button className="hover:text-blue-600" onClick={() => downloadMod(carItem._id, carItem.originalName)}>Download</button>
                            <button className="hover:text-blue-600" onClick={() => handleCopy(carItem._id, carItem.originalName)}>Copy the download link</button>
                        </div>
                    ))}
                </div>
                <div className='h-10'/>
            </div>
        </div>
    );
}

export default CarList