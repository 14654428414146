import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { handleSubmit } from '../../api/hosting';
import { useBackgroundColor } from '../BackgroundColorContext';

function FileUpload() {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    const { isBackgroundBlack } = useBackgroundColor();

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    return (
        <div className={`${isBackgroundBlack ? "bg-zinc-900 text-gray-100" : "bg-white text-black"} font-mono min-h-screen`}>
            <Helmet>
                <title>Alexandre Sammut - ZIP</title>
            </Helmet>
            <div className='min-h-full h-auto m-auto w-2/3 text-center text-2xl'>
                <h1 className='text-5xl my-6'>Envoyer un fichier ZIP</h1>
                <form className="" onSubmit={ async (e) => {
                    e.preventDefault();
                    const result = await handleSubmit(e, file);
                    setMessage(result);
                }}>
                    <div className='my-10 max-w-min max-h-min m-auto text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'>
                        <label className="absolute text-3xl inset-x-2/4 w-1/2 translate-x-[-50%]" for="fileInput">Glissez votre .zip ici</label>
                        <input className="opacity-0 block w-96 h-96" type="file" accept=".zip" onChange={handleFileChange} />
                    </div>
                    {message && <p className='relative -top-3'>{message}</p>}
                    <button className="relative -top-5 text-4xl hover:text-gray-600 mt-6 pb-10" type="submit">Envoyez</button>
                </form>
                
            </div>
        </div>
    );
}

export default FileUpload;