import axios from 'axios';
import { getTokenFromCookie } from './cookie';

export const handleSubmit = async (e, file) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
        const token = getTokenFromCookie
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        };

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_LINK}/ziphoster`, formData, config);
        return(`Le fichier ${response.data.data} a été envoyé avec succès`);
    } catch (error) {
        return("Une erreur est survenue, vérifiez votre rôle ou contactez-moi");
    }
};