import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useBackgroundColor } from './BackgroundColorContext';
import { Helmet } from 'react-helmet-async';
import Rating from 'react-rating';
import { getRating } from '../api/rating'

function Home() {
    const [successMessage, setSuccessMessage] = useState('');
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.successMessage) {
            setSuccessMessage(location.state.successMessage);
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }
    }, [location]);

    const { isBackgroundBlack } = useBackgroundColor();

    const [review, setReview] = useState(null);

    
    useEffect(() => {
        const fetchRating = async () => {
            const rating = await getRating(); // Assuming getRating returns a Promise
            setReview(rating);
        };
    
        fetchRating();
    }, []);

    return (
        <div id="indexHome" className={`${isBackgroundBlack ? "bg-zinc-900 text-gray-100" : "bg-white text-black"} min-h-screen text-center`}>
            <Helmet>
                <title>Alexandre Sammut</title>
                <meta
                    name="description"
                    content="Ce site contient mon CV, des outils que j'ai fait ou d'autre information" 
                />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            {successMessage && <p className="absolute bg-green-600 text-4xl text-center bottom-0 left-0">{successMessage}</p>}
            <div className="w-4/5 sm:w-2/3 m-auto">
                <h1 className="text-3xl sm:text-4xl pt-12 pb-7">Bienvenue dans mon curriculum vitae</h1>
                <p className="text-2xl py-3">Ce site web me sert de: CV et platforme d'expérimentation</p>
                <div className="text-2xl flex flex-col space-y-6 list-none text-blue-400 h-1/2 ml-8 sm:ml-4 my-5 text-left">
                    <Link to="/resume" className="hover:text-gray-600">Mes expériences professionnelles(Mon CV)</Link>
                    <Link to="/learned" className="hover:text-gray-600">Mes connaissances acquises</Link>
                    {/*<Link to="/projects" className="hover:text-gray-600">Des petit programme que j'ai fait</Link>*/}
                    <Link to="/moneyCounter" className="hover:text-gray-600">Money Counter!</Link>
                </div>
                <div className='m-auto sm:w-2/3 my-10 flex flex-col justify-around gap-y-16'>
                    {review && review.map((reviewItem, index) => (
                        <div className='relative min-h-full' key={index}>
                            <h2 className='text-3xl'>{reviewItem.title}</h2>
                            <p className='text-2xl my-3'>{reviewItem.comment}</p>
                            <Rating className="text-2xl" fractions="2" emptySymbol="fa fa-star" fullSymbol="fa fa-star text-yellow-400" initialRating={reviewItem.rating} readonly="true" />
                            <div className='absolute text-right text-2xl -bottom-6 right-10 w-2/3'>
                            {reviewItem.company ? (
                                <div className='relative top-10'>
                                {`-${reviewItem.user.name}`}
                                <br />
                                @{reviewItem.company}
                                </div>
                            ) : (
                                <div>
                                {`-${reviewItem.user.name}`}
                                </div>
                            )
                            }
                            </div>
                        </div>
                    ))}
                </div>
                <p className="sm:text-2xl">Ce site web a été entièrement codé par mes soins en utilisant la bibliothèque <a href="https://fr.legacy.reactjs.org/" className="hover:text-gray-600">ReactJS</a> et le framework CSS <a href="https://tailwindcss.com/" className="hover:text-gray-600">TailWind</a> pour le style. J'ai également employé HTML, JavaScript et du CSS standard à certaines occasions afin d'éviter les répétitions dans le code avec Tailwind. Le backend est gérer par <a href="https://nodejs.org/en" className="hover:text-gray-600">NodeJS</a> avec une base de données <a href="https://www.mongodb.com/" className="hover:text-gray-600">MongoDB</a> 
                <br />Ce site sera mis à jour régulièrement en fonction de mes idées et désirs.</p>
                    <ul className="text-center py-6 sm:text-2xl m-auto sm:w-4/5">
                        <p className="text-center">Mis à jour a venir:</p>
                        <li>Page pour me laisser un message</li>
                        <li>English content will be here soon!/ Ajouter l'option fr/eng sur le site</li>
                        <li>Peux-être ajouter une page pour commander des pièce imprimer en 3D(in the making)</li>
                    </ul>
                </div>
            </div>
    );
}

export default Home;