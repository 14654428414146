import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useBackgroundColor } from '../BackgroundColorContext';
import { requestRole } from '../../api/auth';

function RoleChangement() {
    const [Message, setMessage] = useState('');

    const { isBackgroundBlack } = useBackgroundColor();

    return (
        <div className={`${isBackgroundBlack ? "bg-zinc-900 text-gray-100" : "bg-white text-black"} min-h-screen text-center`}>
            <Helmet>
                <title>Changer le rôle</title>
            </Helmet>
            <div className='w-3/4 m-auto'>
                <h1 className='text-5xl my-10'>Changer votre rôle</h1>
                <p className='text-3xl'>Entrez votre token, cliquez sur "Confirmer" et votre nouveau rôle sera ajouté</p>
                <form className='text-3xl mt-10 flex flex-col gap-y-4' onSubmit={ async (e) => {
                    e.preventDefault();
                    const result = await requestRole(e);
                    setMessage(result);
                }}>
                    <input className={`${isBackgroundBlack ? "" : "border-4 border-black"} m-auto text-black w-3/5 rounded-full`} type="text" autocomplete="off" maxLength={64} name="token" />
                    <input className="hover:text-gray-600" type="submit" value="Confirmer" />
                </form>
                <p className='text-3xl'>{Message ? Message : ""}</p>
            </div>
        </div>
    );
};

export default RoleChangement;