import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const DirectDownload = () => {
    const navigate = useNavigate
    const { fileId, fileName } = useParams();

 useEffect(() => {
    const downloadMod = async () => {
      const apiEndpoint = `${process.env.REACT_APP_BACKEND_LINK}/hq/download/${fileId}`;
      try {
        const response = await axios.get(apiEndpoint, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        navigate('/AC/');
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };

    downloadMod();
 });

 return null;
};

export default DirectDownload;