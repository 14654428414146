import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState, useCallback } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { getUser, logout } from '../../api/auth';

function AuthMenu() {
  const [hoverStyle, setHoverStyle] = useState({ backgroundColor: 'black' });
  const [username, setUsername] = useState(null);

  const navigate = useNavigate()

  useEffect(() => {
    const fetchUsername = async () => {
      const user = await getUser(); // Ensure getUser is awaited
      setUsername(user);
    };

    fetchUsername();
 }, []); 

 const logoutStart = () => {
  setUsername("Invité");
  logout();
 }

  const navigateToRegister = () => {
    navigate('/register');
  };

  const navigateToZip = () => {
    navigate('/zipupload');
  };

  const navigateToAC = () => {
    navigate('/AC');
  };

  const navigateToAccount = () => {
    navigate('/account');
  };

  const navigateToReview = () => {
    navigate('/rating');
  };



  return (
    <div className="absolute left-0 top-1 sm:top-5 w-56 text-right font-mono z-10">
      <Menu as="div" className="absolute left-5 inline-block text-left">
        <div>
        <Menu.Button
          onMouseEnter={() => setHoverStyle({ backgroundColor: 'rgba(0, 0, 0, 0.5)' })}
          onMouseLeave={() => setHoverStyle({ backgroundColor: 'black' })}
          style={hoverStyle}
          className="inline-flex w-full justify-center rounded-md px-4 py-2 text-xl font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
        >
          {username || 'Invité'}
          <ChevronDownIcon
            className="-mr-1 ml-2 h-6 w-5 text-white"
            aria-hidden="true"
          />
      </Menu.Button>
        </div>
        <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1 ">
              {!username && (
                <Menu.Item>
                  {({ active }) => (
                    <button onClick={navigateToRegister}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-black'}
                      group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      S'identifier
                    </button>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button onClick={navigateToAccount}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-black'}
                    group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Paramètre utilisateur
                  </button>
                )}
              </Menu.Item>
              {username && (
                <Menu.Item>
                  {({ active }) => (
                    <button className={`${
                      active ? 'bg-blue-500 text-white' : 'text-black'}
                      group flex w-full items-center rounded-md px-2 py-2 text-sm`} onClick={logoutStart}>Se déconnecter</button>
                  )}
                </Menu.Item>
              )}
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button onClick={navigateToZip}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-black'}
                    group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                    Envoyer des fichier zip
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button onClick={navigateToReview}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-black'}
                    group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                    Commentaire
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button onClick={navigateToAC}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-black'}
                    group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                    AC H-Q
                  </button>
                )}
              </Menu.Item>
            </div>
            
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
};

export default AuthMenu;