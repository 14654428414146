import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Login from '@react-login-page/page10';
import { useNavigate } from 'react-router-dom';
import { useBackgroundColor } from '../BackgroundColorContext';
import { handleSubmit } from '../../api/auth';

function Register() {
    const navigate = useNavigate();

    const [loginError, setLoginError] = useState('');
    const [showError, setShowError] = useState(false);

    const { isBackgroundBlack } = useBackgroundColor();

    useEffect(() => {
        if (showError) {
            const timer = setTimeout(() => {
                setShowError(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showError]);

    const submit = async (e) => {
        e.preventDefault();
        const result = await handleSubmit(e);
        if (result) {
            // Handle error
            console.error(result); // Log the error
            setLoginError('Mauvais email ou mots de passe'); // Set error message
            setShowError(true); // Show error
        } else {
            // Handle success
            navigate('/', { state: { successMessage: 'Login successful' }});
            window.location.reload(); // Reload the page
        }
    };

    


    return (
        <div id="Register" className={`${isBackgroundBlack ? "bg-zinc-900" : "bg-white text-black"} min-h-screen text-gray-100`}>
            <Helmet>
                <title>Register</title>
                <meta name="description" content="Page de connexion pour alexandresammut.com" />
            </Helmet>

            <form onSubmit={submit}>
                <Login style={{ height: 900, zIndex: 0 }}>
                    <Login.TitleSignup>S'inscrire</Login.TitleSignup>
                    <Login.TitleLogin>Se connecter</Login.TitleLogin>
                    <Login.Email placeholder="Votre email" name="userUserName" />
                    <Login.Password placeholder="Votre mots de passe" name="userPassword"/>
                    <Login.Submit keyname="submit" >Se connecter</Login.Submit>

                    <Login.Email panel="signup" placeholder="Votre email" keyname="e-mail" />
                    <Login.Password panel="signup" placeholder="Votre mots de passe" keyname="password" />
                    <Login.Email panel="signup" placeholder="Votre nom" keyname="name" />
                    <Login.Submit panel="signup" keyname="signup-submit">S'inscrire</Login.Submit>
                    {showError && <Login.Footer className="">{loginError}</Login.Footer>}
                </Login>
            </form>
        </div>
    );
}

export default Register;