import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import * as Sentry from "@sentry/react";

//Sentry.init({
//  dsn: "https://3c9c3b139e28c1985f04270f6513387c@o4507143002980352.ingest.us.sentry.io/4507143005470720",
//  integrations: [
//    Sentry.browserTracingIntegration(),
//    Sentry.replayIntegration(),
//  ],
//  // Performance Monitoring
//  tracesSampleRate: 1.0, //  Capture 100% of the transactions
//  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//  tracePropagationTargets: ["localhost", /^https:\/\/alexandresammut\.com/],
//  // Session Replay
//  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

document.documentElement.lang = 'fr';

reportWebVitals();
