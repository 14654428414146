import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useBackgroundColor } from '../BackgroundColorContext';
import { sendResetPassword } from '../../api/auth';

function PasswordReset() {
    const [success, setSuccess] = useState('');

    const { isBackgroundBlack } = useBackgroundColor();

    return (
        <div className={`${isBackgroundBlack ? "bg-zinc-900 text-gray-100" : "bg-white text-black"} text-3xl min-h-screen`}>
            <Helmet>
                <title>Changer le rôle</title>
            </Helmet>
            <div className='w-3/4 m-auto text-center'>
                <h1 className='text-5xl my-10'>Changer votre mots de passe</h1>
                <form className="text-left w-1/2 m-auto flex flex-col gap-y-2" onSubmit={ async (e) => {
                    e.preventDefault();
                    const result = await sendResetPassword(e);
                    setSuccess(result);
                }}>
                    <label>Mots de passe</label>
                    <input className={`${isBackgroundBlack ? "" : "border-4 border-black"} border-black rounded-xl`} type="password" name="currentPassword" />
                    <br /><label>Nouveau mots de passe</label>
                    <input className={`${isBackgroundBlack ? "" : "border-4 border-black"} border-black rounded-xl`} type="password" name="newPassword" />
                    <br />
                    <input className="relative text-4xl hover:text-gray-600 -top-6" type="submit" value="Submit" />
                </form>
                <p>{success ? `Votre mots de passe à été changer` : `Mauvais mots de passe`}</p>
            </div>
        </div>
    );
};

export default PasswordReset;