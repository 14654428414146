import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BackgroundColorProvider, useBackgroundColor } from './componants/BackgroundColorContext';
import Admin from './admin/admin'
import Header from './componants/header/Header';
import DirectDownload from './AC/directDownload';
import Home from './componants/Home';
import Register from './componants/user/register';
import UserAccount from './componants/user/userAccount';
import PasswordReset from './componants/user/passwordReset'
import RoleChangement from './componants/user/roleChangement';
import ZipUpload from './componants/fileHosting/zipUpload';
import Rating from './componants/customerRating';
import Learned from './componants/learned';
import MoneyCounter from './componants/MoneyCounter/MoneyCounter';
import CV from './componants/cv';
import Projects from './componants/projects';
import AC from './AC/ACHome';
import CarList from './AC/CarList';
import TrackList from './AC/TrackList';
import FileUpload from './AC/Upload';

function App() {
  return (
    <Router>
      <HelmetProvider>
        <BackgroundColorProvider>
          <AppContent />
        </BackgroundColorProvider>
      </HelmetProvider>
    </Router>
  );
}

function AppContent() {
  const { isBackgroundBlack } = useBackgroundColor();
  const location = useLocation();
  const navigate = useNavigate();
  const [aKeyPressCount, setAKeyPressCount] = useState(0);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'a' || event.key === 'A') {
        if (location.pathname === '/') {
          setAKeyPressCount((count) => count + 1);
        }
      } else {
        // Reset the count if a key other than 'A' is pressed
        setAKeyPressCount(0);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      if (location.pathname === '/') {
        // Reset the count when the component unmounts
        setAKeyPressCount(0);
      }
    };
  }, [navigate, location.pathname]);

  useEffect(() => {
    // Check the count and navigate if needed
    if (aKeyPressCount === 3) {
      navigate('/AC');
    }
  }, [aKeyPressCount, navigate]);

  const shouldRenderHeader = !location.pathname.startsWith('/AC');

  return (
    <div className={`${isBackgroundBlack ? 'bg-zinc-900' : 'bg-white'} h-full`}>
      {shouldRenderHeader && <Header />}
      <Routes>
        <Route path="/defaultsite" element={<Navigate to="/" />} />
        <Route exact path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/account" element={<UserAccount />} />
        <Route path="/password" element={<PasswordReset />} />
        <Route path="/role" element={<RoleChangement />} />
        <Route path="/zipupload" element={<ZipUpload />} />
        <Route path="/rating" element={<Rating />} />
        <Route path="/learned" element={<Learned />} />
        <Route path="/MoneyCounter" element={<MoneyCounter />} />
        <Route path="/resume" element={<CV />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/AC" element={<AC />} />
        <Route path="/AC/cars" element={<CarList />} />
        <Route path="/AC/tracks" element={<TrackList />} />
        <Route path="/AC/fileUpload" element={<FileUpload />} />
        <Route path="/AC/download/:fileId/:fileName" element={<DirectDownload />} />
      </Routes>
    </div>
  );
}

export default App;