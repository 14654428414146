import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { requestTrackList, downloadMod, handleCopy } from '../api/ACHQ';

function TrackList() {
    const [trackList, setTrackList] = useState(NaN);

    useEffect(() => {
        const fetchTrackList = async () => {
            const trackList = await requestTrackList(); // Assuming getRating returns a Promise
            setTrackList(trackList);
        };
    
        fetchTrackList();
    }, []);
    

    useEffect(() => {
        requestTrackList();
    }, []);

    return (
        <div className='min-h-full font-mono text-white'>
            <Helmet>
                <title>AC Tracks List</title>
            </Helmet>
            <Link to='/AC' className='relative inset-4 text-2xl hover:text-blue-600'>Return to menu</Link>
            <div className='m-auto mt-10 w-2/3 min-h-screen h-auto text-center text-2xl'>
                <h1 className='pt-10 pb-4 text-5xl bg-gradient-to-r from-white to-blue-600 inline-block text-transparent bg-clip-text'>Track List</h1>
                <p>Please do not use the "Copy the download link", it is for bot and server only, if you want to download the mod just click on the "Download" button.</p>
                <div className='my-10 flex flex-col justify-around gap-y-10'>
                    {trackList && trackList.map((trackItem, index) => (
                            <div key={index} className='bg-gray-900 border-2 rounded-xl m-auto w-1/2 text-center flex flex-col'>
                                <h2 className='text-4xl bg-gradient-to-r from-blue-600 to-white text-transparent bg-clip-text'>{trackItem.originalName}</h2>
                                <p>{`Uploaded by ${trackItem.user.name}`}</p>
                                <button className="hover:text-blue-600" onClick={() => downloadMod(trackItem._id, trackItem.originalName)}>Download</button>
                                <button className="hover:text-blue-600" onClick={() => handleCopy(trackItem._id, trackItem.originalName)}>Copy the download link</button>
                            </div>
                    ))}
                </div>
                <div className='h-10'/>
            </div>
        </div>
    );
}

export default TrackList