import React from 'react';
import { useBackgroundColor } from './BackgroundColorContext';

function Projects() {

    const { isBackgroundBlack } = useBackgroundColor();

    return (
        <div id="learnedSection" className={`${isBackgroundBlack ? "bg-black" : "bg-white text-black"} min-h-screen sm:h-2/3 text-center text-gray-100`}>
            <p className="text-3xl w-2/3 m-auto py-12">Malheureusement, la plupart des codes que j'ai fait sont sois protéger par un NDA, des examens privée ou tout simplement supprimer.<br />Si vous voulez plus d'exemple hésiter pas a me contacter mais ce site est un exemple en sois!(Il sera mis à jours dépendament de mes besoin et projets)</p>
            <div className="text-2xl text-blue-400 hover:text-gray-600">    
                <a href='/DtktorV0.1.rar'>Télécharger la démo du projet Dtktor</a>
            </div>
        </div>
    );
}
    
export default Projects;